import { ClientReturn } from "../../assets/icons/ClientReturn";
import { CustomerConfig } from "../../assets/icons/CustomerConfig/CustomerConfig";
import { Debrief } from "../../assets/icons/Debrief/Debrief";
import { FileExport } from "../../assets/icons/FileExport/FileExport";
import { Flag } from "../../assets/icons/Flag";
import { Offshore } from "../../assets/icons/Offshore";
import { Order } from "../../assets/icons/Order/Order";
import { Product } from "../../assets/icons/Product";
import { Route } from "../../assets/icons/Route";
export const menuData = [
  {
    id: 1,
    name: "Event Dashboard",
    icon: <Product />,
    url: "/event-dashboard",
    subMenu: [
      {
        name: "Events",
        url: "/event-dashboard",
      },
      {
        name: "Webhook",
        url: "/event-dashboard/webhook",
      },
      {
        name: "Integration",
        url: "/event-dashboard/integration",
      },
      {
        name: "Clients",
        url: "/event-dashboard/clients",
      },
    ],
  },
  {
    id: 2,
    name: "Product",
    icon: <Product />,
    url: "/product",
    subMenu: [
      {
        name: "overview",
        url: "/product",
      },
      {
        name: "add",
        url: "/product/add",
      },
      {
        name: "category",
        url: "/product/category",
      },
    ],
  },

  {
    id: 3,
    name: "Order",
    icon: <Order />,
    url: "/orders",
    subMenu: [
      {
        name: "overview",
        url: "/orders",
      },
      {
        name: "add",
        url: "/orders/add",
      },
      // {
      //   name: "all-reminder",
      //   url: "/order/all-reminder",
      // },
    ],
  },
  {
    id: 4,
    name: "Route",
    icon: <Route />,
    url: "/routes",
    subMenu: [
      {
        name: "overview",
        url: "/routes",
      },
    ],
  },
  {
    id: 5,
    name: "Task",
    icon: <Flag />,
    url: "/flag",
    subMenu: [
      {
        name: "overview",
        url: "/flag",
      },
    ],
  },
  {
    id: 6,
    name: "Return",
    icon: <ClientReturn />,
    url: "/orders/view/return",
    subMenu: [
      {
        name: "overview",
        url: "/orders/view/return",
      },
      // {
      //   name: "return order",
      //   url: "/returnclient/return/",
      // },
      {
        name: "return after order",
        url: "/orders/view/return/overview",
      },
    ],
  },
  {
    id: 7,
    name: "Finance",
    icon: <Debrief />,
    url: "/finance",
    subMenu: [
      {
        name: "Debrief",
        url: "/finance",
      },
      {
        name: "Invoice",
        url: "/finance/invoice/ready-for-invoice",
      },
    ],
  },

  {
    id: 8,
    name: "File export ",
    icon: <FileExport />,
    url: "/file-export",
    subMenu: [
      {
        name: "overview",
        url: "/file-export",
      },
    ],
  },

  {
    id: 9,
    name: "Offshore",
    icon: <Offshore />,
    url: "/offshore",
    subMenu: [
      {
        name: "overview",
        url: "/offshore",
      },
    ],
  },
  {
    id: 10,
    name: "Customer Config",
    icon: <CustomerConfig />,
    url: "/customer-config",
    subMenu: [
      {
        name: "overview",
        url: "/customer-config",
      },

      {
        name: "Supplier ",
        url: "/supplier",
      },
    ],
  },

  // {
  //   id: 5,
  //   name: "Invoice",
  //   icon: <Invoice />,
  //   url: "/invoice",
  //   subMenu: [
  //     {
  //       name: "overview",
  //       url: "/invoice",
  //     },
  //   ],
  // },
];
